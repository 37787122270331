@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    /* Font Sizes */
    --fs-xs: 0.8125rem; /* 13px */
    --fs-sm: 0.875rem; /* 14px */
    --fs-md: 1rem; /* 16px */
    --fs-lg: 1.5rem; /* 24px */
    --fs-xl: 1.75rem; /* 28px */
    --fs-2xl: 2rem; /* 32px */
    --fs-3xl: 2.75rem; /* 44px */

    /* Colors */
    --beige: 249 247 241;
    --orange: 255 243 202;
    --orange-light: 255 218 167;
    --orange-brand: 247 147 33;
    --orange-dark: 223 77 44;
    --orange-hover: 209 64 32;
    --orange-active: 185 57 28;
    --melon: 255 191 177;
    --red-light: 254 242 242;
    --red: 223 44 44;
    --red-hover: 209 32 32;
    --red-active: 185 28 28;
    --green-light: 240 253 239;
    --green: 21 128 60;
    --green-dark: 22 101 52;
    --blue-light: 239 246 255;
    --blue: 29 78 216;
    --purple: 89 80 125;
    --purple-hover: 80 72 112;
    --purple-active: 71 64 100;
    --purple-dark: 46 41 64;
    --grey-lightest: 248 247 246;
    --grey-light: 236 236 234;
    --grey: 225 225 222;
    --grey-dark: 153 153 153;
    --grey-darkest: 51 51 51;

    --primary-100: var(--beige);
    --primary-200: var(--orange);
    --primary-300: var(--orange-light);
    --primary-400: var(--melon);
    --primary-500: var(--orange-brand);
    --primary-700: var(--orange-dark);
    --primary-800: var(--orange-hover);
    --primary-900: var(--orange-active);

    --secondary-400: var(--purple);
    --secondary-500: var(--purple-hover);
    --secondary-600: var(--purple-active);
    --secondary-700: var(--purple-dark);

    --danger-100: var(--red-light);
    --danger-500: var(--red);
    --danger-600: var(--red-hover);
    --danger-700: var(--red-active);

    --info-100: var(--blue-light);
    --info-400: var(--blue);

    --success-100: var(--green-light);
    --success-400: var(--green);
    --success-600: var(--green-dark);

    --grey-100: var(--grey-lightest);
    --grey-300: var(--grey-light);
    --grey-400: var(--grey);
    --grey-500: var(--grey-dark);
    --grey-700: var(--grey-darkest);

    /* Radius */
    --border-radius: 0.25rem; /* 4px */

    /* Max Widths */
    --max-w-30: 7.5rem; /* 120px */
    --max-w-40: 10rem; /* 160px */
    --max-w-45: 11.25rem; /* 180px */
    --max-w-50: 12.5rem; /* 200px */
    --max-w-60: 15rem; /* 240px */
    --max-w-70: 17.5rem; /* 280px */
    --max-w-80: 20rem; /* 320px */
    --max-w-90: 22.5rem; /* 360px */
    --max-w-100: 25rem; /* 400px */
    --max-w-120: 30rem; /* 480px */
    --max-w-140: 35rem; /* 560px */
    --max-w-270: 67.5rem; /* 1080px */

    /* Layout */
    --navbar-width: 5rem; /* 80px */
  }
}
